body {
	background-color: #F3F5F7;
}

.buttonsWrapper {
	/*background-color: #211D52;*/
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;

}

.chapterBtn {
	border: solid 1px #211D52;
	color: #211D52;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
}

.chapterBtn:hover {
	cursor: pointer;
}

.chapterBtn.active {
	color: white;
	background-color: #211D52;
}

 h1 {
 	font-size: 30px;
 	letter-spacing: 1px;
 }

.versesContainer {
	width: 1024px;
	min-height: 80vh;
	margin: auto;
}

.informationContent {
	padding: 40px 0;
	height: 400px;
	display: block;
	overflow-y: scroll;
	line-height: 24px;
	word-spacing: 5.5px;
	width: 100%;
	font: 15px 'SimSun';
    line-height: 30px;
    white-space: pre-line;
    text-align: center;
}

@media only screen and (max-width: 1023px) {
  .versesContainer {
    width: 90%;
  }
}


.verseContent {
	border-bottom: solid 1px #CCC;
}


.verseContent.clickable:hover{
	cursor: pointer;
}

.verseContent label {
	width: 20px;
}

.MuiDrawer-paper {
	width: 250px;
}

.rightMenu .MuiTypography-body1 {
	text-align: right;
	padding-right: 20px;
}

.loadingPortal {
	background-color: black;
	opacity: 0.4;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loadingPortal .loadingArea {
	color: black;
	font-weight: bold;
	background-color: white;
	width: 100px;
	height: 80px;
	text-align: center;
	padding: 20px;
	margin: auto;
	top: 50%;
	position: relative;
	border-radius: 15px;
}

.settingIconDiv {
	position: absolute;
	top: 30%;
	right: 0px;
}

.settingIconDiv:hover {
	cursor: pointer;
}

.MuiList-root svg {
	position: absolute;
	right: 120px;
}

.MuiListItem-root .MuiSlider-root {
	width: 60%;
	position: absolute;
	right: 40px;
}

.MuiFormControlLabel-root .MuiCheckbox-colorSecondary.Mui-checked {
    color: #155251;
}


.dialogTitleContainer {
	display: flex;
	width: 90%;
	/*border: solid 1px red;*/
	justify-content: space-between;
}

.dialogTitleContainer p {
	/*border: solid 1px green;*/
	padding: 0;
	margin: 0;
	width: calc(100% - 65px);
}

.dictionaryName, .chainName {
	color: blue;
	font-weight: bold;
	font-size: 18px;
	padding: 0;
	margin: 0;
}

.dictionaryDefinition, .chainDefinition {
	margin-top: 0;
}

.popupLegend {
	margin: 0;
}

.dictionaryContainer {
	margin-bottom: 15px;
}

.chainContainer {
	display: flex;
}

.chainContainer .chainDefinition {
	/*padding: 0 18px;*/
	margin: 0;
}

.chainContainer .chainContentContainer {
	width: calc(100% - 20px - 65px);
	margin-bottom: 10px;
}

.chainContainer .crIdx {
	color: red;
	margin: 0;
	font-size: 15px;
	position: relative;
	top: 2px;
	width: 20px;
}

.chainContainer .chainBtnsContainer {
	width: 65px;
	display: flex;
	justify-content: space-around;
}

.chainContainer .chainBtnsContainer .chainBtn,
.dialogTitleContainer .dialogBtn,
.dictionaryContainer .dialogBtn {
	border: solid 1px #E0E0E0;
	border-radius: 5px;
	box-shadow: 1px 2px 1px 2px #ccc;
	color: gray;
}

.chainContainer .chainBtnsContainer .chainBtn:hover,
.dialogTitleContainer .dialogBtn:hover,
.dictionaryContainer .dialogBtn:hover {
	cursor: pointer;
}

.dictionaryContainer .dialogBtn {
	position: relative;
	left: 90%;
}

.dialogTitleBtns {
	width: 65px;
	display: flex;
	justify-content: space-between;
}

.informationFormControl, .dualLanguageFormControl {
	width: 400px;
}

.informationFormControl .informationBtn {
	background-color: #155251;
	color: white;
	margin-top: 20px;
}

.roundComm {
	background: #cdcdcd;
	border: 1px solid #cdcdcd;
	border-radius: 80px;
	padding: 5px 6px;
	font-size: 12px;
	margin-left: 10px;
}

.documentDialog > div {
	text-align: left;
	line-height: 15px;
}

.informationContent {
	text-align: left;
	line-height: 25px;
}

.MuiSwitch-root {
	overflow: unset !important;
}

.Component-switchBase-2 {
	top: 1px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	top: 0 !important; 
}

@media only screen and (max-width: 600px) {
  .informationFormControl, .dualLanguageFormControl {
    width: 250px;
  }

  .dualLanguageFormControl .dialog-select-label {
  	font-size: 9px;
  }
}

.dualLanguageVersionSelectionDialog .dualLanguageFormControl {
	margin: 10px;
}

.dualLanguageVersionSelectionDialog + button {
	background-color: #155251;
	color: white;
	margin-top: 20px;
	width: 100%;
}

.informationFormControl .informationBtn:hover {
	background-color: #155251;
}

.shareIcon {
	margin-right: 10px;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter .MuiAlert-filledSuccess {
	background-color: #155251;
}