.dayDiv {
	width: 80px;
	height: 80px;
	border-radius: 80px;
	background-color: #0F514F;
	color: white;
    font-weight: bold;
    line-height: 80px;
    text-align: center;
    font-size: 20px;
}

.banner {
	width: 100%;
	max-width: 444px;
	margin-top: -33px;
}

.bannerDiv {
	position: relative;
    color: white;
	font-size: 28px;
	font-weight: bold;
}

.bannerDiv p {
	position: absolute;
	bottom: 0px;
	padding-left: 10px;
}



.closeBtn {
	position: absolute; 
	top: -20px; 
	right: 10px;
	color: white;
	z-index: 100;
}

.closeBtn:hover {
	cursor: pointer;
}

.planDesc p {
	margin: 0;
	padding: 2px 0;
}

.customPlanRootDiv {
	position: relative;
}

.customPlanDiv.bannerDiv p {
	position: absolute;
	bottom: 60px;
	padding-left: 10px;
}

.planNameTextField .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl,
.planDescTextField .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
	width: 30%;
	margin-left: 5%;
}

.planNameTextField label, .planDescTextField label {
	margin-left: 5%;
}

.planNameTextField input,
.planNameTextField label,
.planDescTextField input,
.planDescTextField label {
	color: white;
}

.planNameTextField .MuiFormLabel-root.Mui-focused,
.planDescTextField .MuiFormLabel-root.Mui-focused {
	color: white;
}

.planNameGrid {
	position: absolute;
	display: flex;
    bottom: 25px;
}


.planNameTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.planNameTextField .MuiOutlinedInput-notchedOutline,
.planNameTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.planDescTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.planDescTextField .MuiOutlinedInput-notchedOutline,
.planDescTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
	border-color: white;
}

.customPlanBookRootDiv h1 {
	padding: 0;
	margin: 0;
	font-size: 20px;
	letter-spacing: 2px;
	text-align: left;
	width: 100%;
	margin-left: 30px;
}

.customPlanBookRootDiv .bookGrid {
	max-height: 300px;
	overflow-y: scroll;
	margin: 5px 0;
}

.customPlanBookRootDiv .bookDiv {
	position: relative;
}

.customPlanBookRootDiv .bookDiv img {
	width: 120px;
}

.customPlanBookRootDiv .bookDiv p {
	position: absolute;
	text-align: center;
	color: white;
	top: 0;
	width: 120px;
	height: 30px;
	line-height: 30px;
}

.customPlanBookRootDiv .bookChapterDiv {
	position: relative;
}

.customPlanBookRootDiv .bookChapterDiv img {
	width: 50px;
}

.customPlanBookRootDiv .bookChapterDiv p {
	position: absolute;
	text-align: center;
	top: 0;
	color: white;
	width: 50px;
	height: 50px;
}

.customPlanPreviewRootDiv .bannerDiv h5 {
	position: absolute;
    bottom: 10px;
    margin: 0;
    padding-left: 10px;
    font-size: 15px;
}

.customPlanPreviewRootDiv .daysDiv {
	display: flex;
	flex: 1;
	margin: 20px 0;
	overflow: auto;
	max-width: 100%;
}

.customPlanPreviewRootDiv .daysDiv div {
	background-color: #95b1af;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	color: white;
	min-width: 50px;
	margin: 5px;

}

.customPlanPreviewRootDiv .daysDiv div p {
	width: 100%;
	text-align: center;
	font-size: 15px;
}

.customPlanPreviewRootDiv .daysDiv div.selected {
	border: solid 2px black;
}

.customPlanPreviewRootDiv .daysDiv div:hover {
	cursor: pointer;
}

.customPlanPreviewRootDiv .bookItem {
	width: 40%;
	height: 100px;
}

.customPlanPreviewRootDiv .bookItem .cover img {
	width: 50%;
	margin-left: 5px;
}

.customPlanPreviewRootDiv .bookItem .cover p { 
	width: 50%;
	/*border: solid 1px red;*/
	margin-left: 5px;
	font-size: 12px;
	top: calc(( 50% - 10px ) / 2);
	letter-spacing: 0px;
}

.customPlanPreviewRootDiv .bookItem .desc {
	position: absolute;
	right: 5px;
	width: 45%;
	max-height: 100%;
	text-align: center;
	/*border: solid 1px green;*/
	overflow: hidden;
}

.customPlanPreviewRootDiv .bookItem .desc h1 {
	font-size: 15px;
	margin: 0;
	padding: 0;
}

.customPlanPreviewRootDiv .bookItem .desc p {
	font-size: 10px;
	margin: 0;
	padding: 0;
	line-height: 12px;
	text-align: left;
}

.bookDiv img:hover, .bookDiv p:hover, .bookChapterDiv img:hover, .bookChapterDiv p:hover {
	cursor: pointer;
}

@media only screen and (max-width: 600px) {

	.customPlanDiv.bannerDiv p {
		position: absolute;
		bottom: 130px;
		padding-left: 0px;
	}
}
