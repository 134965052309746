

.topicSearchContainer {
	width: calc(100% - 40px);
	padding: 20px;
	display: block;
}

.topicCatItem {

}

.topicItem > p {
	cursor: pointer;
}

.topicItem:hover {
	cursor: pointer;
}

.topicCatItem .topicCatContainer {
	background-color: rgba(15,52,51,0.4);
	display: block;
	padding: 12px 20px;
	width: calc(100% - 40px);
	font-size: 24px;
	font-weight: bold;
}

.topicCatItem .topicContainer {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.topicCatItem.dicCatItem .topicContainer {
	flex-wrap: wrap;
	flex-direction: row;
	flex: 1;
	flex-flow: 1;
}

.topicCatItem .topicContainer .topicItem {
	margin: 20px;
	justify-content: flex-start;
}

.topicCatItem.dicCatItem .topicContainer .topicItem {
	margin-bottom: 0px;
}

.topicCatItem .topicContainer .topicItem .topicContent .teaser, .topicCatItem .topicContainer .topicItem .topicContent .source{
	display: none;
}

.topicCatItem .topicContainer .topicItem .topicContent.active .teaser, 
.topicCatItem .topicContainer .topicItem .topicContent.active .source{
	display: block;
}

.topicCatItem .topicContainer .topicItem .topicContent {
}

.topicCatItem .topicContainer .topicItem .topicContent.active {
	display: block;
    background-color: white;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px #EFF1F6;
    box-shadow: 3px 3px 5px 3px #ccc;
	position: relative;
	z-index: 1;
	max-width: 600px;
	min-height: 500px;
}

.topicCatItem.dicCatItem .topicContainer .topicItem .topicContent {
	display: block;
    background-color: white;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px #EFF1F6;
    box-shadow: 3px 3px 5px 3px #ccc;
	position: relative;
	z-index: 1;
}

.topicCatItem.dicCatItem .topicContainer .topicItem .topicContent.active {
	max-width: calc(100%);
}

.topicCatItem.dicCatItem .topicContainer .topicItem {
	width: calc(50% - 40px);
}

.topicCatItem .topicContainer .topicItem .topicContent .source {
	font-size: 12px;
}

.topicCatItem .topicContainer .topicItem .topicContent .title {
	font-size: 24px;
	font-weight: bold;
	margin-top: 0;
}

.topicCatItem .topicContainer .topicItem .topicContent .smallText {
	font-size: 10px;
	font-weight: bold;
	margin-bottom: 0;
}




@media only screen and (max-width: 767px) {

	.topicCatItem.dicCatItem .topicContainer .topicItem {
		width: 100%;
	}

}





