

.planItem {
	width: 400px;
	position: relative;
}

.planItem.registered {
	opacity: 0.5
}

.planItem img {
	width: 100%;
}

.planItem .desc {
	background-color: rgba(21, 82, 81, 0.7);
	color: white;
	position: absolute;
	bottom: 2px;
	width: 100%;
	height: 40%;
	opacity: 0.7;
	/*padding: 0 10px;*/
}

.planItem .desc h1 {
	margin: 5px 10px 2px;
}

.planItem .desc p {
	margin: 10px;
}

.planItem .desc p {
	margin-top: 0;
	font-size: 14px;
}

.plansContainer {
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
}

.plansContainer .planItem {
	margin: 10px;
	cursor: pointer;
}

.planItem .closeBtn {
	position: absolute;
	top: 0;
	margin-top: 10px;
}