
.resourceItem {
    min-height: 200px;
    width: 400px;
    background-color: white;
    border-radius: 2px;
    border: solid 1px #EFF1F6;
    box-shadow: 3px 3px 5px 3px #ccc;
    display: block;
    margin: 30px 15px;
}

.resourceItem .collectBtn:hover,
.resourceItem .closeBtn:hover {
	cursor: pointer;
}

.resourceItem > a {
	text-decoration: none;
	color: black;
	width: 100%;
	display: block;
}

.resourceItem > a > div {
    display: flex;
}

.resourceItem .cover {
	position: relative;
	margin-right: 20px;
}

.resourceItem .cover p {
	position: absolute;
    top: calc( 50% - 42px );
    margin-top: 0;
    width: calc( 100% - 20px);
    /*background-color: red;*/
    margin-left: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 0;

}

.resourceItem .desc h1 {
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 0;
	padding: 0;
	overflow-wrap: break-word;
    word-break: break-word;
}

.resourceItem .desc p {
	margin-top: 10px;
	padding-right: 10px;
    font-size: 14px;
	overflow-wrap: break-word;
    word-break: break-word;
	overflow: hidden;
	display: -webkit-box;
    -webkit-line-clamp: 16;
	-webkit-box-orient: vertical;
}

.resourceItem .desc h1 {
	padding-right: 40px;
}

.resourceItem img {
	width: 130px;
	margin-top: -20px;
	margin-left: 20px;
	box-shadow: 3px 3px 5px 3px #ccc;
}

.videoCatItem .categoryMenu {
	float: left;
	border: none;
	height: inherit;
	margin-bottom: 0;
}

.videoCatItem .categoryMenu li {
	margin-left: 4px;
    font-size: 20px;
    font-weight: bold;
    cursor: auto;
}

.videoCatItem .separator {
	width: calc(100% - 40px);
	border-bottom: 1px solid #DFDFDF;
	margin: 0 0 0 40px;
}

.videoContainer::-webkit-scrollbar {
	display: none;
}

.videoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
	white-space: nowrap;
	overflow-wrap: break-word;
    width: calc(100% - 40px);
    padding: 20px;
}


.videoItem {
	width: 400px;
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
}

.videoItem .cover {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
}

.videoItem .dim {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(44,44,44,0.8);

	display: none;
	justify-content: center;
	align-items: center;
}

.videoItem .cover:hover .dim {
	display: flex;
}

.videoItem .dim a {
	text-decoration: none;
	background-color: rgba(44,44,44,0);
	color: white;
	margin: 10px;
	border: 1.5px white solid;
	border-radius: 20px;
	padding: 4px 20px;
}

.videoItem .dim a:hover {
	background-color: white;

}

.videoItem .dim a span {
	font-weight: bold;
}

.videoItem .dim a:hover span {
	color: rgba(44,44,44,1);
}

.videoItem img {
	width: 100%;
	height: 220px;
	object-fit: cover;
	object-position: center;
	display: block;
}

.videoItem .desc {
	display: block;
	width: 100%;

}

.videoItem .desc h1 {
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 0;
	padding: 0;
	overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

.videoItem .desc p {
	margin-top: 10px;
	padding-right: 10px;
    font-size: 14px;
	overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

.sortTable {
	text-align: right;
}

.sortTable svg {
	margin-bottom: 13px;
}

.sortTable p {
	
}

.sortTable p::after {
	content: " ";
    display: block;
    position: relative;
	padding-bottom: 10px;
	border-bottom: 3px solid transparent;
	width: 50%;
	margin: auto;
}

.sortTable p.active::after {
	content: " ";
    display: block;
    position: relative;
	padding-bottom: 10px;
	border-bottom: 3px solid #63CAE0;
	width: 50%;
	margin: auto;
}



@media only screen and (max-width: 767px) {

	.videoCatItem .categoryMenu {
		padding-top: 10px;
		padding-left: 20px;
	}

	.videoContainer {
		flex-direction: column;
		width: calc(100% - 20px);
    	padding: 10px;
	}

	.videoItem {
		width: calc(100% - 20px);
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		padding: 10px;
	}

	.videoCatItem .separator {
		width: calc(100%);
	    margin: 0 0 0 0;
	}

}










