.subMenu a {
	color: white;
	text-decoration: none;
}

.subMenu a.active {
	font-weight: bold;
}

.subMenu .menuIcon {
	color: white;
	display: none;
}

.subMenu .menuIcon:hover,
.profileIcon:hover,
.logoutArea:hover,
.lockIcon:hover {
	cursor: pointer;
}

.leftMenu .MuiTypography-body1 {
	text-align: left;
	padding-left: 20px;
}

.loginRegisterContainer .MuiFormLabel-root.Mui-focused {
	color: #0F514F;
}

.loginRegisterContainer .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #0F514F;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
	overflow: hidden;
}

.headerMenu span:hover {
	cursor: pointer;
}

.searchPopOverContainer {
	position: relative;
	display: block;
}

.searchPopOverContainer:after {
	content:"";
	position: absolute;
	right: 4px;
	top: -24px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 8px 8px;
	border-color: transparent transparent white transparent;
	z-index:9998;
}

.searchPopOverTabPanel .MuiButton-text {
	/*width: 100%;*/
	margin: auto;
	padding: 8px;
}

.searchPopOverTabPanel {
	margin-top: 10px;
}

.selectedMenu {
	color: #3f51b5;
	border-bottom: 1px solid #3f51b5;
}

.searchConfirmBtn {
	width: 120px;
    margin: auto;
    text-align: center;
    background: #acacac;
    height: 40px;
    line-height: 40px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.875rem;
    box-shadow: 1px 1px 1px #cdcdcd;
    -webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.searchConfirmBtn.active {
    background: #155251;
}

.MuiTabs-indicator {
	min-width: 160px;
}

.mobileSearch {
	display: none;
}

.header div span b, .MuiPaper-root div span b {
	font-weight: normal;
}

.footer p {
	color: white;
	width: 80%;
	display: block;
	margin: auto;
    padding: 0 10%;
    line-height: 50px;
}

@media only screen and (max-width: 1023px) {
	.subMenu .menuIcon {
		color: white;
		display: block;
		margin-left: 10%;
		position: relative;
		top: calc((100% - 24px)/2);
	}

	.subMenu .subMenuList, .header .headerMenu {
		display: none;
	}

	.searchPopOverContainer {
		display: none;
	}

	.mobileSearch {
		position: absolute;
		right: 20px;
		display: block;
		margin-top: -15px;
	}

}	