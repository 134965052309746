.container {
	/*width: 1100px;*/
	margin: auto;
	position: relative;
}

.dayContainer {
	display: flex;
	margin: 20px;
	max-width: 100vw;
	overflow-x: scroll;
}

.dayContainer > div {
	margin-right: 10px;
	width: 50px;
	cursor: pointer;
}

.planBookContainer {
	/*width: 1100px;*/
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	margin-top: 20px;
}

.planBookContainer .bookItem {
	width: 333px;
}

.container .header {
	width: 100%;	
	height: 300px;
}

.container .header.banner1 {
	background: url('../../images/BiblePlanCovers/1.jpg')  center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container .header.banner2 {
	background: url('../../images/BiblePlanCovers/2.jpg')  center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container .header.banner3 {
	background: url('../../images/BiblePlanCovers/3.jpg')  center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container .header.banner4 {
	background: url('../../images/BiblePlanCovers/4.jpg')  center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container .header.banner5 {
	background: url('../../images/BiblePlanCovers/5.jpg')  center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container .overlay {
	background-color: #155251;
	position: absolute;
	top: 0;
	height: 300px;
	width: 100%;
	opacity: 0.3;
}

.container .planName {
	background-color: #155251;
	color: white;
	height: 50px;
	width: 100%;
	position: absolute;
	bottom: 0;
}

.container .planName p {
	line-height: 50px;
	font-size: 20px;
	margin: 0;
    text-indent: 20px;
}

.dateBox {
	width: 60px;
	height: 60px;
	background-color: #FF7B7B;
	text-align: center;
	color: white;
	border-radius: 5px;
	line-height: 20px;
	min-width: 60px;
}

.dateBox.today{
	background-color: #7FBF7F;
}

.dateBox.future{
	background-color: gray;
}

.dateBox .date {
	font-size: 12px;
}

.dateBox p {
	margin: 0;
}

.progress {
	color: white;
	background-color: black;
	border: solid 5px gray;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-weight: bold;
	bottom: 30px;
	right: 20px;
	border-radius: 30px;
	position: absolute;
	z-index: 100;
}

.remainingDays {
	position: absolute;
	bottom: 30px;
	left: 20px;
	color: white;
	font-size: 30px;
	font-weight: bold;
}

.remainingDays span {
	font-size: 40px;
}

.settingIcon {
	position: absolute;
	top: 20px;
	right: 20px;
	color: white;
}

.slider-control-centerright button {
	width: 50px;
	height: 50px;
	background-color: #155251!important;
}

.slider-control-bottomcenter { display: none }