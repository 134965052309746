.planSwitchBtn {
	color: #155251;
	border: solid 1px #155251;
}

.planSwitchBtn.active {
	background-color: #155251;
	color: white;
	border: solid 1px #155251;
}

.planSwitchBtn.active:hover {
	background-color: #155251;
}