


.mapPopup {
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;
	align-content: center;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.4);
	overflow-y: hidden;
}

.mapPopup > img {
	width: 50%;
	display: block;
}