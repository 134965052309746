


.egwBookItem {
	max-width: 240px;
    margin: 30px 15px;
    cursor: pointer;
}

.egwBookItem img {
	width: 100%;
	display: block;
}

.egwBookItem .desc h1 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}


.flexBox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 40px;
}

.flexBox > div:first-of-type {
	display: block;
	width: 25%;
	margin-right: 40px;
	position: relative;
}

.flexBox > div:first-of-type > div {
	position: relative;
}

.flexBox > div:first-of-type img {
	display: block;
	width: 100%;
	margin-top: 1em;
}

.flexBox > div:last-of-type {
	width: 55%;
}

.flexBox > div:last-of-type p {
	font-size: 1.2em;
	cursor: pointer;
	font-weight: 400;
}

.flexBox > div:last-of-type p:hover {
	font-weight: 500;
	text-decoration: underline;
}

.borderBox {
	width: 100%;
	height: 100%;
	position: absolute;
	border: 0px solid #dcdcdc;
	/*background: #dcdcdc;*/
	top: 4px;
	left: 4px;
	z-index: -1;
}

.borderBox p {
	margin-top: 30px;
    margin-left: 80px;
    margin-right: 100px;
    font-weight: 500;
}

.egwSegment {
	display: none;
	max-height: 500px;
	overflow-y: scroll;
}

.egwSegment.active {
	display: block;
}

.egwSegment > div {
	margin: 15px 0;
}

.playPauseBtnBox {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	top: calc(50% - 20px);
	left: calc(50% - 44px);
	width: 88px;
	margin-top: 10px;
}

.playPauseBtn, .prevNextIcon {
	width: 40px !important;
	height: 40px;
	margin: 10px;
	cursor: pointer;
}

.linkContainer {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
}

.linkContainer > div {
	background: #155251;
	padding: 16px 48px;
	margin: 8px;

}

.linkContainer > div a {
	color: white;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
}

.prevNextContainer {
	position: relative;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.prevNextContainer .prevIcon {
	position: absolute;
	top: -66px;
	left: 0;
}
.prevNextContainer .nextIcon {
	position: absolute;
	top: -66px;
	right: 0;
}


@media only screen and (max-width: 767px) {

	.flexBox {
		margin: 20px 40px;
		flex-direction: column-reverse;
	}

	.flexBox > div:first-of-type {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;
	}

	.borderBox {
		background-color: #fff;
		top: 0;
		left: 0;
	}

	.flexBox > div:first-of-type img {
		max-width: 60px;
		padding: 4px;
	}

	.playPauseBtnBox {
		position: absolute;
		justify-content: flex-end;
		top: calc(50% - 32px);
	    left: calc(100% - 98px);
		margin-top: 0px;
	}

	.playPauseBtn {
		width: 20px !important;
		height: 20px;
	}

	.flexBox > div:last-of-type {
		width: 100%;
	}

	.linkContainer {
		position: absolute;
		bottom: 0;
		margin-left: 60px;
	}	

	.linkContainer > div {
		background: transparent;
		padding: 10px;
		margin: 0px;
		margin-left: 10px;

	}

	.linkContainer > div a {
		color: #155251;
		font-weight: 600;
		text-decoration: none;
	}

	.prevNextContainer {
		display: none;
	}
}





