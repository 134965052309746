body {
	background-color: #F3F5F7;
}

.header {
	/*display: flex;*/
}

.header .menuIcon {
	color: white;
}

.header > div {
	float: right;
	line-height: 80px;
	padding-right: 5%;
}

.header div span {
	margin: 0 15px; 
	display: inline-block;
}

.header img.logo {
	/*margin-top: 20px;*/
	margin-left: 10%;
	height: 80px;
}

.banner {
	width: 100%;
}

.subMenu {
	height: 50px;
	background-color: #155251;
}

.subMenu.footer {
	height: auto;
}

.subMenu ul {
	list-style: none;
	padding: 0 10%;
	margin: 0;
	white-space:nowrap;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.subMenu ul::-webkit-scrollbar {
  display: none;
}

.subMenu ul li {
	display: block;
	color: white;
	height: 50px;
	/*width: 100px;*/
	margin: 0 20px;
	line-height: 50px;
}

.landBookItem {
	margin: 30px 10px;
}
.landingContainer .landBookItem {
	margin: 30px 5px;
}

.bookItem {
	height: 200px;
	width: 400px;
	background-color: white;
	border-radius: 2px;
	border: solid 1px #EFF1F6;
	box-shadow: 3px 3px 5px 3px #ccc;
	display: flex;
	margin: 30px 15px;
}

.bookItem .collectBtn:hover,
.bookItem .closeBtn:hover {
	cursor: pointer;
}

.landBookItem .cover {
	position: relative;
	margin-right: 20px;
}

.bookItem .cover {
	position: relative;
	margin-right: 20px;
}

.bookItem .cover p {
	position: absolute;
    top: calc( 50% - 42px );
    margin-top: 0;
    width: calc( 100% - 20px);
    /*background-color: red;*/
    margin-left: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 0;
    cursor: pointer;
}

.landBookItem .cover p {
	position: absolute;
    /*top: calc( 50% - 10px );*/
    top: 0;
    width: calc( 100% - 0px);
    /*background-color: red;*/
    margin-top: 0;
    margin-left: 0px;
    text-align: center;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0;
    cursor: pointer;


    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
    bottom: 0;
    margin-bottom: 5px;
}

.bookItem .cover img:hover, .landBookItem .cover img:hover {
	cursor: pointer;
}

.bookItem .desc h1, .landBookItem .desc h1 {
	font-size: 24px;
	margin-top: 10px;
	margin-bottom: 0;
	padding: 0;
}

.bookItem .desc p, .landBookItem .desc p {
	margin-top: 10px;
	padding-right: 10px;
}

.bookItem img {
	width: 130px;
	margin-top: -20px;
	margin-left: 20px;
	box-shadow: 3px 3px 5px 3px #ccc;
}

.landBookItem img {
	width: 150px;
	box-shadow: 3px 3px 5px 3px #ccc;
}

.booksContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.categoryMenu {
	list-style: none;
	text-align: left;
	padding-top: 20px;
	/*border-top: solid 1px #EAEDF0;*/
	border-bottom: solid 1px #EAEDF0;
}

.categoryMenu li {
	display: inline-block;
	/*width: 100px;*/
    margin: 0 20px;
	height: 40px;
	text-align: center;
}

.categoryMenu li:hover {
	cursor: pointer;
}

.categoryMenu li.active {
	font-weight: bold;
	/*border-bottom: solid 3px #63CAE0;*/
}

.categoryMenu li.active::after {
        content: '';
        display: block;
        position: relative;
        margin-top: 15px;
        width: 30%;
        left: 35%;
        border-bottom: 3px solid #63CAE0;
    }

.landingContainer {

}

.landingSubContainer {
	display: flex;
	flex-direction: row;
	align-content: space-between;
	width: 80%;
	margin: auto 10%;
	position: relative;
	border-bottom: 1px solid #afafaf;
	margin-bottom: 40px;
}

.landingSubContainer h2 {
	width: calc(100%);
}

.landingSubContainer > div {
	width: 40px;
	line-height: 2.5em;
}

.landingSubContainer > div a {
	color: #9c9c9c;
	text-decoration: none;
	font-size: 14px;
}

.landingContainer .booksContainer {
	margin: 0 8%;
	align-content: flex-start;
	justify-content: flex-start;
	padding-left: 20px;
}

.bannerContainer {
	margin-bottom: 40px;
	position: relative;
}

.bannerContainer .imageBookContainer {
	width: 25%;
	display: block;
	position: absolute;
	top: 0;
	left: 10%;
	margin: auto;
}

.bannerContainer .imageBookContainer .bookCoverItem {
	width: 100%;
	height: 50%;
	object-fit: cover;
	display: block;
	margin: auto;
	margin-left: 0%;
	padding-top: 80px;
	padding-bottom: 60px;
	padding-left: 20px;
} 

.bannerGradient {
	position: relative;
	width: 80%;
	background: transparent;
	margin-left: 20%;
}

.bannerContainer .bannerGradient .bannerSwiperItem {
	width: calc(90% - 46px);
	display: block;
	margin-top: 10px;
	margin-left: 10%;
	padding: 16px;
	border: 2px solid #dededf;
}

.documentDialog {
	position: absolute;
    background: white;
    z-index: 2000;
    padding: 20px;
    left: 25%;
    top: 10%;
    box-shadow: 4px 6px #dededf;
    border: 2px solid #dededf;
}

.documentDialog > div {
	height: 600px;
	display: block;
	overflow-y: scroll;
	line-height: 24px;
	word-spacing: 5.5px;
	width: 100%;
	font: 15px 'SimSun';
    line-height: 30px;
    white-space: pre-line;
    text-align: center;
}

.landingContainer .booksContainer .bookItem {
	width: auto;
	background-color: transparent;
	border: none;
	box-shadow: none;
}

.landingContainer .booksContainer .bookItem .cover {
	margin-right: 0px;
}

.landingContainer .booksContainer .bookItem .desc {
	display: none;
}

.landingContainer .booksContainer .bookItem img {
	margin-left: 0;
}

.landingContainer .booksContainer .bookItem .cover p {
	margin-left: 0;
	width: 100%;
}

.landingContainer .resourceItem {
	width: 350px;
}

.landingContainer .resourceItem .desc h1 {
	padding-right: 10px;
}

.landingContainer .videoItem {
	width: 350px;
}

.landingContainer .otherResItem {
	width: 350px;
    border-radius: 2px;
    display: block;
    margin: 0px 15px;
    position: relative;
    margin-bottom: 40px;
}

.landingContainer .otherResItem img {
	display: block;
	width: 100%;
}

.landingContainer .otherResItem h2 {
	position: absolute;
    top: 50%;
    margin: 0;
    text-align: center;
    width: 100%;
    color: white;
}

.footerDesktopMenu {
	display: block;
}

.footerTabletMenu {
	display: none;
}

.footerMobileMenu {
	display: none;
}


@media only screen and (max-width: 1023px) {

	.categoryMenu {
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    overflow: scroll;
		white-space: nowrap;
		overflow-wrap: break-word;
	}

	.categoryMenu::-webkit-scrollbar {
		display: none;
	}

	.categoryMenu li {

	}

	.footerDesktopMenu {
		display: none;
	}

	.footerTabletMenu {
		display: block;
	}

	.footerMobileMenu {
		display: none;
	}

	.subMenu.footer p {
		line-height: 30px;
	}


}


@media only screen and (max-width: 767px) {


	.bannerGradient {
	    width: 100%;
	    left: 0%;
	    margin-left: 0;
	    position: relative;
	}

	.bannerContainer .bannerGradient .bannerSwiperItem {
		border: none;
		width: 100%;
		left: 0;
		margin: 0;
		padding: 0;
	}

	.bannerContainer .imageBookContainer {
		display: none;
	}

	.documentDialog {
		position: absolute;
	    background: white;
	    padding: 0px;
	    left: 4px;
	    right: 4px;
	    top: 10%;
	    box-shadow: 4px 6px #dededf;
	    border: 2px solid #dededf;
	}

	.documentDialog > div {
		height: 80vh;
		display: block;
		overflow-y: scroll;
		line-height: 24px;
		word-spacing: 5.5px;
		width: 100%;
	}


	.documentDialog > div > div {
		width: 100% !important;
		margin: 10px !important;
	}


	.documentDialog > div div {
		max-width: 300px !important;
		margin-top: 10px !important;
		white-space: pre-wrap !important;
	}

	.documentDialog > div p {
		margin: 10px;
		padding: 0px;
	}

	.landingContainer .booksContainer {
		margin: 0 4%;
		padding-left: 10px;
	}

	.landingContainer .videoItem {
		width: 300px;
	}

	.landingContainer .otherResItem {
		width: 300px;
	    border-radius: 2px;
	    display: block;
	    margin: 0px 15px;
	    position: relative;
	    margin-bottom: 20px;
	}


}

/* mobile footer only */
@media only screen and (max-width: 620px) {

	.footerDesktopMenu {
		display: none;
	}

	.footerTabletMenu {
		display: none;
	}

	.footerMobileMenu {
		display: block;
	}
}


